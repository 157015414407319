import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Bio from './pages/Bio/Bio'
import { useEffect } from 'react'

import { amplitude } from './components/amplitude'

import { BrowserRouter as Router, Route } from 'react-router-dom'
const App = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent('App Homepage visited')
  }, [])
  return (
    <div className="app">
      <Router>
        <Route path="/" component={Bio} exact />
      </Router>
    </div>
  )
}

export default App
