import eshop1 from '../../images/eshop1.svg'
import covid from '../../images/covid.svg'
import blog from '../../images/blog.svg'
import netflix from '../../images/netflix.svg'
import wildfire from '../../images/wildfire.svg'
import chef from '../../images/chef.svg'
import amazon from '../../images/amazon.svg'
import vchat from '../../images/v-chat.jpg'
import { Card, Col, Row } from 'react-bootstrap'

import './Projects.css'

const Projects = () => {
  return (
    <>
      <div className="projects__heading">
        <h2>Projects</h2>
      </div>
      <div className="projects">
        <Row>
          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img variant-="top" src={eshop1} alt="eshop" />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://e-shop-app-7.herokuapp.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    E-Shop App
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  E-Commerce store built with MERN stack and hosted on Heroku!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img variant-="top" src={chef} alt="Chef Booking" />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://chef-booking-app.herokuapp.com/login"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    Chef Booking App
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  A marketplace app built with team of 3 other developers using
                  MERN stack to book chef in your vicinity (Hatchways co-op)
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img variant-="top" src={covid} alt="Covid Tracker" />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://gracious-kowalevski-187895.netlify.app/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    Covid-19 Tracker
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  Live Covid cases tracker built with React.js using disease.sh
                  API, hosted on Netlify!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img
                variant-="top"
                src={vchat}
                alt="Video chat application"
              />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://v-chat-app.netlify.app/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    Video Chat Application
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  Video chat application built with React.js, deployed on
                  Netlify
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img variant-="top" src={netflix} alt="Netflix app" />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://github.com/Vaibhav-Kambli/netflix-react"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    Netflix App (GitHub)
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  Netflix app built with React, redux and firebase
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img variant-="top" src={wildfire} alt="wildfire" />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://sharp-tesla-67a580.netlify.app//"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    Wildfire Tracker
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  Wildfire tracker built with React.js and leaflet maps using
                  NASA's Open API
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img variant-="top" src={amazon} alt="wildfire" />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://clone-f6800.web.app/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    Amazon Website
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  Amazon website's clone built with React.js, Context API,
                  Stripe, firebase and firestore.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="align-items-stretch d-flex"
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Card className="project__card">
              <Card.Img variant-="top" src={blog} alt="Next.js Blog site" />

              <Card.Body>
                <a
                  style={{ color: 'grey', fontWeight: 'bold' }}
                  href="https://nextjs-blogs-theta.vercel.app/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Card.Title as="div" style={{ color: '#3b5998' }}>
                    Next.js Blog site
                  </Card.Title>
                </a>
                <Card.Text as="div">
                  Personal Blog site built with Next.js and deployed on Vercel!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Projects
