import amplitude from 'amplitude-js'

const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

// const API_KEY1 = env === "app" ? "PRODUCTION_API_KEY_HERE" : "DEV_API_KEY_HERE";
//If you only have one environment, just put the single API key
amplitude.getInstance().init(API_KEY, null, {
  // configuration options
  includeUtm: true,
  includeGclid: true,
  includeReferrer: true,
})
export { amplitude }
