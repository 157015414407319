import React, { useRef } from 'react'
import glasses from '../../images/glasses.gif'
import location from '../../images/location2.gif'
import tool from '../../images/tool.gif'
import './Bio.css'
import Projects from '../Projects/Projects'
import emailjs from 'emailjs-com'

import { amplitude } from '../../components/amplitude'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
const USER_ID = process.env.REACT_APP_USER_ID

const Bio = () => {
  const myRef = useRef(null)
  const topRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  const executeScrollUp = () => scrollToRef(topRef)

  const handleSubmit = () => {
    const timeElapsed = Date.now()
    const today = new Date(timeElapsed)
    let time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()

    const message = `Your Resume was downloaded from portfolio site on ${today.toDateString()} at ${time}`

    try {
      emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          message: message,
          to_name: 'Vaibhav',
        },
        USER_ID
      )
    } catch (err) {
      console.log(err)
    }

    amplitude.getInstance().logEvent('download clicked')
  }

  // function to handle linkedIn button click
  const handleLinkedInClick = (e) => {
    window.open('https://www.linkedin.com/in/vaibhav-kambli', '_blank')
    amplitude.getInstance().logEvent('LinkedIn button clicked')
  }
  // function to handle Gmail button click
  const handleGmailClick = () => {
    window.location.href = 'mailto:vaibhavk2608@gmail.com'
    amplitude.getInstance().logEvent('Gmail button clicked')
  }
  // function to handle GitHub button click
  const handleGitHubClick = () => {
    window.open('https://github.com/Vaibhav-Kambli', '_blank')
    amplitude.getInstance().logEvent('GitHub button clicked')
  }

  return (
    <div className="bio__container">
      <div className="bio" ref={topRef}>
        <section className="bio__section">
          <div className="bio__heading">
            <div>
              <div className="bio__intro">
                <h6>HELLO!</h6>
                <h1>
                  I'm <span className="bio__name">Vaibhav </span>
                </h1>
                <p>
                  Full Stack Web Developer located in Canada{' '}
                  <img
                    src={location}
                    className="bio__location"
                    alt="location"
                  />
                </p>
                <section className="bio__contact">
                  <ul>
                    <li>
                      <span onClick={handleGitHubClick}>
                        <i className="fa fa-github" aria-hidden="true"></i>
                      </span>
                    </li>
                    <li>
                      <span onClick={handleLinkedInClick}>
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </span>
                    </li>
                    <li>
                      <span onClick={handleGmailClick}>
                        <i className="fa fa-google" aria-hidden="true"></i>
                      </span>
                    </li>
                  </ul>
                </section>
                <div className="bio__resume">
                  <a href="/resume/Vaibhav_Resume.pdf" download>
                    <button
                      onClick={() => handleSubmit()}
                      className="btn_download__resume"
                    >
                      <i className="fa fa-download"></i> Download My Resume
                    </button>
                  </a>
                </div>
                <section>
                  <div className="bio__scroll" onClick={executeScroll}>
                    <button className="btn__scroll">
                      Click / scroll to see projects{' '}
                      <i className="fa fa-long-arrow-down "></i>
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="bio__image">
            <div className="bio__data">
              <h1>
                About Me{' '}
                <img src={glasses} className="bio__glasses" alt="glasses" />
              </h1>
              <p>
                I am a Full-Stack developer and a post graduate specializing in
                web and mobile app development. My primary focus is on building
                web/mobile applications and interfaces that will solve real
                world problems and improve workflow efficiency.
              </p>

              <h1>
                Tools & Technology{' '}
                <img src={tool} className="bio__tool" alt="tool" />
              </h1>
              <p>Web foundations: HTML5, CSS3, JavaScript</p>
              <p>Frameworks: React.js, Vue.js, React Native</p>
              <p>Backend: Node.js, Express.js, SQL and NoSQL databases</p>
              <p>Testing: Jest, Mocha, Chai</p>
            </div>
          </div>
        </section>
      </div>

      <section id={'projects'} className="bio__projects">
        <div ref={myRef}>
          <Projects />
        </div>
      </section>
      <div className="bio__up">
        <section>
          <div className="bio__scrollup" onClick={executeScrollUp}>
            <button className="btn__scrollup">
              Back to top <i className="fa fa-long-arrow-up "></i>
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Bio
